export class User {
    client_id: number = 0;
    client_name: string = '';
    client_mobile: string = '';
    client_city: string = '';
    loggedIn: boolean = false;
    async user() {

    }

    async profileIsComplete() {
        let complete = true;
        Object.values(this).forEach((val) => {
            if (val == '') {
                complete = false;
            }
        });
        return complete;
    }

};