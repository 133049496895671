import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import * as Constants from '../../res/app.constants';
import { User } from '../../data-types/user'
import { AuthService } from '../auth/auth.service';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userdata: any = '';
  mobile: any = '';
  public userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  private authkey = '';
  constructor(
    private authServ: AuthService,
    private dibcHttp: DibcHttpService
  ) {
    this.init();
  }

  async init() {

    this.authkey = await this.getAuthKey();
    console.log(this.authkey)
    if (this.authkey) {
      console.log('aaa')
      this.getProfile();
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
  }
  async getAuthKey() {
    if (!this.authkey) {
      this.authkey = await this.authServ.getAuthkey();
    }
    return this.authkey;
  }



  // async login(logindata) {
  //   console.log(logindata);
  //   const url = Constants.PUB_API_PATH + 'login';
  //   const apiResp = await this.dibcHttp.post(url, logindata);
  //   if (apiResp.status == 200) {
  //     if (apiResp.user) {
  //       this.userObj = apiResp.user;
  //       this.userObj.loggedIn = true;
  //       this.user.next(this.userObj);
  //       return this.userObj;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // }


  async logout() {
    this.authServ.clear();
    this.userObj = new User();
    this.user.next(this.userObj);
  }
  async getProfile() {
    if (this.authkey) {
      if (this.userObj.client_name.length == 0) {
        await this.getUserProfileFromServer();
      }
    }
    return this.userObj;
  }
  async getUserProfileFromServer() {
    const url = Constants.USER_API_PATH + 'get_profile';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.status === 200) {
      this.userObj = respData.profile;
      this.userObj['loggedIn'] = true;
      this.user.next(this.userObj);
    }
    return this.userObj;
  }
  async register(userdata) {
    const url = Constants.PUB_API_PATH + 'register';
    const respData = await this.dibcHttp.post(url, userdata);
    return respData;
  }
  async otpVerification(otpData) {
    const url = Constants.PUB_API_PATH + 'otp_verification';
    const respData = await this.dibcHttp.post(url, otpData);
    if (respData.status == 200) {
      if (respData.clientid) {
        this.userObj.client_id = respData.clientid;
        this.userObj.loggedIn = true;
        this.user.next(this.userObj);
        return this.userObj;
      } else {
        return respData;
      }
    } else {
      return false;
    }
  }
  async resendOtp(otpData) {
    const url = Constants.PUB_API_PATH + 'resend_otp';
    const respData = await this.dibcHttp.post(url, otpData);
    return respData;
  }
}
