import { Component } from '@angular/core';
import { Cart } from './data-types/cart';
import { User } from './data-types/user';
import { OrderService } from './services/order/order.service';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user = new User();
  cart: any = new Cart();
  constructor(
    private userServ: UserService,
    private orderServ: OrderService
  ) {
    this.userServ.user.subscribe(async u => {
      this.user = u;
      console.log(u)
    });
    this.orderServ.cart.subscribe(async ord => {
      this.cart = ord;
    });
  }

}
