
export const INT_API_URL = 'https://localhost/JEWELLERY-API/public/';
export const EXT_API_URL = 'https://jewellery.dibcs.co.in/public/';

export const API_PATH = 'api/web/';
export const PUB_API_PATH = 'pub/';
export const USER_API_PATH = 'user/';



export const REGEX_EMAIL = '^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+[\.]+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$';
export const REGEX_PHONE = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const REGEX_PASSWORD = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{4,}/;
export const REGEX_PAN = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
export const REGEX_GST = " \d{ 2}[A-Z]{ 5 } \d{ 4 } [A - Z]{ 1 } [A - Z\d]{ 1 } [Z]{ 1 } [A - Z\d]{ 1 }";
export const REGEX_PIN = "[1-9][0-9]{5}"
export const REGEX_TEXT = "[A-Za-z 0-9*]+";
export const REGEX_NAME_TEXT = "[A-Za-z .*]+";

