import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { Cart } from 'src/app/data-types/cart';

import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  cartObj: any = new Cart();
  // public userObj = new User();
  public cart: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(this.cartObj);
  products: any = [];
  productWt: any = {};
  orders: any = [];
  cartPurity: any = {};
  cartPatch: any = {};
  cartSize: any = {};
  pid: any = '';
  catProductList = {};
  purity: any = 92;
  patch: any = "";
  size: any = "";

  totalWt: any = {};
  totalQty: any = {};
  categories: any = [];
  constructor(
    private dibcHttp: DibcHttpService,
    private storage: Storage,
  ) {
  }

  async getCategoriesFromApi() {
    const url = Constants.PUB_API_PATH + 'product_category';
    const resp = await this.dibcHttp.post(url, {});
    if (resp.categories) {
      this.categories = resp.categories;
      return resp.categories;
    }
    return [];
  }
  async getCategories() {
    if (this.categories && this.categories.length > 0) {
      return this.categories;
    } else {
      return await this.getCategoriesFromApi();
    }
  }
  async getCategory(cid) {
    let category = {};
    if (this.categories.length == 0) {
      await this.getCategories();
    }
    console.log(cid)
    console.log(this.categories)
    this.categories.forEach(c => {
      if (c.cate_id == cid) {
        category = c;
      }
    });
    return category;
  }


  async getProductsFromApi() {
    const url = Constants.PUB_API_PATH + 'get_products';
    const resp = await this.dibcHttp.post(url, {});
    this.products = resp.products;
    this.products.forEach(p => {
      this.productWt[p.prod_id] = p.prod_weight;
    });
    return this.products;
  }

  async getProducts() {
    if (this.products && this.products.length > 0) {
      return this.products;
    } else {
      return await this.getProductsFromApi();
    }
  }
  async getCatProducts(catId) {
    if (this.catProductList[catId] && this.catProductList[catId].length > 0) {
      return this.catProductList[catId];
    } else {
      return await this.getCatProductsFromAllProducts(catId);
    }
  }

  async getCatProductsFromAllProducts(catId) {
    this.catProductList[catId] = [];
    const allProductList = await this.getProducts();
    allProductList.forEach((i, j) => {
      if (i.cate_id == catId) {
        this.catProductList[catId].push(i);
      }
    });
    return this.catProductList[catId];
  }
  async getCartFromStorage() {
    await this.storage.get('cartPurity').then(async cartPurity => {
      if (cartPurity && cartPurity.length > 0) {
        this.cartPurity = JSON.parse(cartPurity);
      }
    });
    await this.storage.get('cartPatch').then(async cartPatch => {
      if (cartPatch && cartPatch.length > 0) {
        this.cartPatch = JSON.parse(cartPatch);
      }
    });
    await this.storage.get('cartSize').then(async cartSize => {
      if (cartSize && cartSize.length > 0) {
        this.cartSize = JSON.parse(cartSize);
      }
    });
    await this.storage.get('cart').then(async cart => {
      if (cart && cart.length > 0) {
        this.cartObj = JSON.parse(cart);
        // this.events.publish('cart:change', Object.keys(this.cartObj).length);
        this.cart.next(this.cartObj);

        this.calcTotal();
      }
    });
    return this.cartObj;
  }
  async getCart() {
    if (this.cartObj && Object.keys(this.cartObj).length > 0) {
      return this.cartObj;
    } else {
      await this.getCartFromStorage();
    }
    //  this.events.publish('cart:change', Object.keys(this.cartObj).length);
    this.cart.next(this.cartObj);

    this.calcTotal();
    return this.cartObj;
  }

  setCart(cart) {
    this.cartObj = cart;
    this.storage.set('cart', JSON.stringify(this.cartObj));
    // this.events.publish('cart:change', Object.keys(this.cartObj).length);
    this.cart.next(this.cartObj);

    this.calcTotal();
  }
  addToCart(productId, qty) {

    this.cartPurity[productId] = this.purity;
    this.cartPatch[productId] = this.patch;
    this.cartSize[productId] = this.size;
    if (typeof this.cartObj[productId] !== 'undefined') {
      this.cartObj[productId] += qty;
    } else {
      this.cartObj[productId] = qty;
    }
    this.storage.set('cart', JSON.stringify(this.cartObj));
    this.storage.set('cartPurity', JSON.stringify(this.cartPurity));
    this.storage.set('cartPatch', JSON.stringify(this.cartPatch));
    this.storage.set('cartSize', JSON.stringify(this.cartSize));
    // this.events.publish('cart:change', Object.keys(this.cartObj).length);
    this.cart.next(this.cartObj);
    this.calcTotal();
    return this.cartObj;
  }
  removeFromCart(productId) {

    if (typeof this.cartObj[productId] !== 'undefined') {
      delete this.cartObj[productId]; // Remove the all entry for the productId
      this.storage.set('cart', JSON.stringify(this.cartObj));
      this.cart.next(this.cartObj);
      this.calcTotal();
    }
    return this.cartObj;

  }
  async changeQty(productId, qty) {
    if (typeof this.cartObj[productId] == 'undefined') {
      return this.cartObj;
    } else if (this.cartObj[productId] <= 0) {
      delete this.cartObj[productId];
    } else {
      this.cartObj[productId] = qty;
    }
    this.storage.set('cart', JSON.stringify(this.cartObj));
    this.cart.next(this.cartObj);

    this.calcTotal();
    return this.cartObj;
  }
  async order() {

    const url = Constants.USER_API_PATH + 'order_products';
    const data = {
      cart: JSON.stringify(this.cartObj),
      cartPurity: JSON.stringify(this.cartPurity),
      cartPatch: JSON.stringify(this.cartPatch),
      cartSize: JSON.stringify(this.cartSize),
    };

    const resp = await this.dibcHttp.post(url, data);
    if (resp.status == 200) {
      this.cartObj = {};
      this.cartPurity = {};
      this.storage.set('cart', this.cartObj);
      this.storage.set('cartPurity', this.cartPurity);
      this.cart.next(this.cartObj);
      this.calcTotal();
    }
    return this.cartObj;
  }
  async calcTotal() {
    this.totalWt = {};
    this.totalQty = {};
    const prodIds = Object.keys(this.cartObj);
    const allPurities = Array.from(new Set(Object.values(this.cartPurity)));

    allPurities.forEach((purity: any) => {
      this.totalWt[purity] = 0;
      this.totalQty[purity] = 0;
    });

    prodIds.forEach((p) => {
      allPurities.forEach((purity: any) => {
        if (this.cartPurity[p] == purity) {
          this.totalWt[purity] += this.cartObj[p] * this.productWt[p];
          this.totalQty[purity] += this.cartObj[p] * 1;
        }
      });
    });
  }
  async myproducts() {
    const url = Constants.USER_API_PATH + 'orderproducts';
    const resp = await this.dibcHttp.post(url, '');
    this.products = resp.products;
    return this.products;
  }

  // async myOrders() {
  //   const url = Constants.USER_API_PATH + 'orders';
  //   const resp = await this.dibcHttp.post(url, {});
  //   if (resp.orderproducts) {
  //     return resp.orderproducts;
  //   } else {
  //     return [];
  //   }
  // }

  async cancelorder(orderid) {
    const url = Constants.USER_API_PATH + 'cancelorder/' + orderid;
    const resp = await this.dibcHttp.post(url, '');
  }
  async orderData() {
    const url = Constants.USER_API_PATH + 'get_orders';
    const resp = await this.dibcHttp.post(url, {});
    this.orders = resp.orderdata;
    return this.orders;
  }

  async orderDetails(orderid) {
    const url = Constants.USER_API_PATH + 'orderDetails/' + orderid;
    const resp = await this.dibcHttp.post(url, '');
    return resp.orderproducts;
  }
  async dataorders(orderid) {
    const url = Constants.USER_API_PATH + 'orderDetails/' + orderid;
    const resp = await this.dibcHttp.post(url, '');
    return resp.orderdata;
  }
  async ClientCountmsg() {
    const url = Constants.PUB_API_PATH + 'count_msg';
    const resp = await this.dibcHttp.post(url, {});
    console.log(resp);
    return resp.countmsg;
  }
  async allmessage() {
    const url = Constants.PUB_API_PATH + 'get_msg';
    const resp = await this.dibcHttp.post(url, {});
    console.log(resp);
    return resp.messages;
  }
  async prodDetailefromserver(prodid) {
    const url = Constants.USER_API_PATH + 'orderProduct/' + prodid;
    const resp = await this.dibcHttp.post(url, '');
    return resp.productdtls;
  }

  async appSlide() {
    const url = Constants.PUB_API_PATH + 'appslide';
    const resp = await this.dibcHttp.post(url, '');
    console.log(resp);
    return resp.messages;
  }
}
